import { createRoot } from "react-dom/client";
import {Home} from './pages/home';
import {Data2025} from './pages/library';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import {BrowserRouter, Route, Routes} from "react-router";

const searchClient = algoliasearch('64LMXTN0XN', '79c4a700b51a2af70dd807fb5a61decb');

const App = () => {
    return (
        <InstantSearch searchClient={searchClient} indexName="prod_state-of-finops">
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<Home />} />
                    <Route path={'/library'} element={<Data2025 />} />
                </Routes>
            </BrowserRouter>
        </InstantSearch>
    );
}

createRoot(document.getElementById("dataApp")).render(<App />);