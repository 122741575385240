import { jsx as H, Fragment as T } from "react/jsx-runtime";
import C, { useState as W, useRef as j, useEffect as A, Children as B } from "react";
const N = ({ root: t, rootMargin: u, threshold: a, onChangeActiveId: d }) => {
  const [c, g] = W(null), n = j([]);
  return A(() => {
    const b = (s) => {
      s.forEach((e) => {
        const k = e.target.getAttribute("id"), o = n.current.find((h) => h.id === k);
        o && (o.ratio = e.isIntersecting ? e.intersectionRatio : 0);
      });
      const i = Math.max(...n.current.map((e) => e.ratio), 0.1), r = n.current.find((e) => e.ratio === i);
      g(r && r.id), r && r.id && c !== r.id && typeof d == "function" && d(r.id, c);
    }, m = { root: t, rootMargin: u, threshold: a }, p = new IntersectionObserver(b, m);
    return n.current.forEach(({ id: s }) => {
      const i = document.getElementById(s);
      i && p.observe(i);
    }), () => {
      p.disconnect();
    };
  }, [n, t, u, a]), { idsRef: n, activeLink: c };
}, O = (t) => t.scrollHeight > t.clientHeight || t.scrollWidth > t.clientWidth ? t : t.parentElement ? O(t.parentElement) : document.scrollingElement || document.documentElement, w = ({
  activeClass: t = "",
  activeAttr: u = !1,
  offsetTop: a = 0,
  offsetLeft: d = 0,
  behavior: c = "smooth",
  root: g = null,
  rootMargin: n = "0px",
  threshold: b = [0, 0.25, 0.5, 0.75, 1],
  onClickEach: m = null,
  onChangeActiveId: p = null,
  children: s = null
}) => {
  const { idsRef: i, activeLink: r } = N({ root: g, rootMargin: n, threshold: b, onChangeActiveId: p }), e = (k) => B.map(k, (o) => {
    const { type: h, props: l } = o, { href: y, className: x, onClick: I } = l || {};
    if (h === w)
      return o;
    if (h === "a" && y && y.startsWith("#")) {
      const v = y.slice(1), L = v === r;
      return i.current.find((f) => f.id === v) || i.current.push({ id: v, ratio: 0 }), C.cloneElement(o, {
        className: L ? [x, t].join(" ") : x,
        "data-active": u ? L : null,
        onClick: (f) => {
          f.preventDefault(), typeof I == "function" && I(f);
          const R = document.getElementById(v), E = O(R), S = () => {
            E.scrollTo({
              top: R.offsetTop - E.offsetTop - a,
              left: R.offsetLeft - E.offsetLeft - d,
              behavior: c
            });
          };
          typeof m == "function" ? m(f, S, E) : S();
        }
      });
    }
    return l && l.children ? C.cloneElement(o, {
      ...l,
      children: e(l.children)
    }) : o;
  });
  return /* @__PURE__ */ H(T, { children: e(s) });
};
export {
  w as default
};
